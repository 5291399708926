<template>
	<div>
		<b-row>
			<b-col>
				<ag-grid-table :configUrl="detalleBlendingTableConfig" :dataUrl="detalleBlendingTableData"
					:editUrl="detalleBlendingTableEdit">
				</ag-grid-table>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<bruna-element-loader :dataUrl="detalleBlendingChart">
				</bruna-element-loader>
				<b-button variant="primary" to="/dashboard/fiordo/blending/historico-blending">Volver a la página anterior</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";

import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import BrunaElementLoader from "@/views/brunacomponents/BrunaElementLoader.vue";
import useApiServices from "@/services/useApiServices.js";

export default {
	components: {
		BRow,
		BCol,
		BButton,
		AgGridTable,
		BrunaElementLoader,
	},
	data() {
		return {
			blendingId: null,
			cellValue: null,
			detalleBlendingChart: null,
			detalleBlendingTableConfig: null,
			detalleBlendingTableData: null,
			detalleBlendingTableEdit: null,
		};
	},
	beforeMount() {
		// this.params contains the cell & row information and is made available to this component at creation time
		// see ICellRendererParams below for more details
		this.cellValue = this.getValueToDisplay(this.params);
		let blending_id = this.$route.params.blending_id;
		this.detalleBlendingChart =
			useApiServices.detalleBlendingChart + "/" + blending_id;
		this.detalleBlendingTableConfig =
			useApiServices.detalleBlendingTableConfig + "/" + blending_id;
		this.detalleBlendingTableData =
			useApiServices.detalleBlendingTableData + "/" + blending_id;
		this.detalleBlendingTableEdit =
			useApiServices.detalleBlendingTableEdit + "/" + blending_id;
	},
	methods: {
		// gets called whenever the user gets the cell to refresh
		refresh(params) {
			// set value into cell again
			this.cellValue = this.getValueToDisplay(params);
		},
		getValueToDisplay(params) {
			return params;
			//return params.valueFormatted ? params.valueFormatted : params.value;
		},
	},
};
</script>

<style scoped>

</style>
